.facets {
    --facets-horizontal-margin: 16px;

    @media (--to-large) {
        --facets-horizontal-margin: 0;
        --background-color: var(--site-background);
        display: none;
        left: 0;
        top: 0;
        transition: left 0.4s ease-in-out;
        position: fixed;
        padding: 16px 16px 0;
        z-index: 20;
        background-color: var(--background-color);
        max-width: 375px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        &.visible {
            display: block;

            &::before {
                content: '';
                display: block;
                background: rgba(0, 0, 0, 0.7);
                position: fixed;
                top: 0;
                left: 375px;
                right: 0;
                bottom: 0;
                transition: background 0.4s ease-in;
                z-index: 15;
            }
        }

        &.selected-facet {
            --background-color: var(--color-white);
        }
    }

    h3 {
        font-size: 16px;
        font-weight: bold;
        display: inline;
    }
}

.selected-facets {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 24px;

    &:empty {
        margin: 0;
    }
}

.apply-button-wrapper {
    position: sticky;
    bottom: 0;
    width: 100%;
    padding-top: 48px;
    padding-bottom: 18px;
    z-index: 10;
    margin-top: auto;
    background: linear-gradient(0deg, var(--background-color) 80%, rgba(255, 255, 255, 0));

    @media (--to-xsmall) {
        /* make room for the chat bubble */
        padding-bottom: 72px;
    }

    @media (--from-large) {
        display: none;
    }

    button {
        background-color: var(--color-swamp);
        border: none;
        border-radius: var(--border-radius);
        color: var(--color-white);
        padding: 15px 25px;
        font-size: 14px;
        width: 100%;
        text-align: center;

        &:hover,
        &:focus {
            background-color: #000;
        }
    }
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    @media (--from-large) {
        margin-bottom: 16px;
    }

    h2 {
        flex: 1 1 auto;
        font-size: 16px;
        /* this prevents the Nullstill button from changing height when hovered */
        margin-bottom: 1px;
    }
}

.close-facets-button {
    background-color: transparent;
    font-size: 32px;

    @media (--from-large) {
        display: none;
    }
}